<template>
  <div>
    <loader :isLoading="data_loaded"></loader>
  </div>
  <div>
    <div class="card shadow">
      <div class="card-body">
        <div class="row">
          <dic class="col-md-12">
              <div
                class="tab-pane fade show active"
                id="tabs-icons-text-1"
                role="tabpanel"
                aria-labelledby="tabs-icons-text-1-tab"
              >
                <data-table
                  v-if="data_loaded"
                  id="approved"
                  :options="dtb_options.extra_options"
                  :actions="dtb_options.actions"
                  :data="dtb_options.data"
                  :columns="dtb_options.columns"
                />
              </div>
          </dic>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import DataTable from "@/components/Datatable";
import Loader from "../../components/Loader";
import {alert} from "@/utils/alertUtils";
export default {
  name: "Entities",
  components: {
    "data-table": DataTable,
    loader: Loader,
  },
  data() {
    return {
      data_loaded: false,
    
      dtb_options: {
        data: [],
        actions: [
          {
            btnText: "Detail",
            btnClass: "btn btn-default btn-sm",
            iconClass: "fa fa-info-circle",
            btnFn: (uuid) => {
                this.$router.push({
                    name: "Business Detail",
                    params: { uuid: uuid },
                  });
            },
            btnParamName: "uuid",
          },
        ],
        columns: [
          { title: "Name", data: "name" },
          { title: "Phone", data: "phone" },

        ],
        extra_options: {},
      },
    
     
    };
  },
  methods: {

    fetch_entities: function () {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/entities`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          this.dtb_options.data = res.data;
          this.data_loaded = true;
        })
        .catch((err) => {
          if(err.response.status===403 || err.response.status===401){
            alert.error("Session Expired, Please Login again");
            this.$store.dispatch("logout");
          }else{
            alert.error("Error occurred, Try again later");
          }
        });
    },
 
   
  
  },
  created() {
    this.fetch_entities();
  },
 
};
</script>
<style lang="css" scoped>
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #172a4d;
}
.nav-pills .nav-link {
  color: #172a4d;
}
.nav-pills .nav-link:hover {
  color: #172a4d;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link:hover {
  color: white;
}
</style>